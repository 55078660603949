'use client';

import type { ComponentPropsWithoutRef } from 'react';
import { useState } from 'react';
import classNames from 'classnames';

import { ShowAllToggle } from '@/components/atoms/ShowAllToggle';
import styles from './index.module.scss';
import { RepairDetail } from './components/RepairDetail';

export interface RepairsListTranslations {
  showMoreText: string;
  showLessText: string;
}

export interface RepairsListProps extends ComponentPropsWithoutRef<'div'> {
  readonly translations: RepairsListTranslations;
  readonly className?: string;
  readonly garageServices?: string[];
}

export function RepairsList({ garageServices, translations, className }: RepairsListProps): JSX.Element {
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };
  const { showMoreText, showLessText } = translations;

  return (
    <div className={classNames(styles.component, className, styles.RepairsList)}>
      <div className={classNames(styles.servicesContainer, showAll ? styles.Visible : styles.Hidden)}>
        {garageServices?.map((serviceName) => <RepairDetail key={serviceName} serviceName={serviceName} />)}
      </div>
      <ShowAllToggle
        showAll={showAll}
        toggleShowAll={toggleShowAll}
        showMoreText={showMoreText}
        showLessText={showLessText}
        styleType="labelStyle"
      />
    </div>
  );
}

RepairsList.displayName = 'RepairsList';
